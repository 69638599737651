import api from "@/api";

const store = {
  get() {
    return api.get(`/store`);
  },
  apply(data) {
    return api.post(`/store/apply`, data);
  },
  changeLicense(data) {
    return api.patch(`/store/license`, data);
  },
  getTodo() {
    return api.get(`/store/todo`);
  },
  getStatistics() {
    return api.get(`/store/statistics`);
  },
  getAccountBook() {
    return api.get(`/store/account-book`);
  },
  listPoint(params) {
    return api.get(`/store/point`, {
      params: params,
    });
  },
  setPassword(data) {
    return api.patch(`/store/password`, data);
  },
  setImg(data) {
    return api.patch(`/store/img`, data);
  },
  setShareImg(data) {
    return api.patch(`/store/share-img`, data);
  },
  setBulletin(data) {
    return api.patch(`/store/bulletin`, data);
  },
  setIntroduce(data) {
    return api.patch(`/store/introduce`, data);
  },
  setCellphone(data) {
    return api.patch(`/store/cellphone`, data);
  },
  setService(data) {
    return api.patch(`/store/service-item`, data);
  },
  setBusiness(data) {
    return api.patch(`/store/business`, data);
  },
  setState(data) {
    return api.patch(`/store/state`, data);
  },
  setEarlyDuration(data) {
    return api.patch(`/store/early-duration`, data);
  },
  setMoneyLimit(data) {
    return api.patch(`/store/money-limit`, data);
  },
  setTimeLimit(data) {
    return api.patch(`/v2/store/early-duration`, data);
  },
  listPeriodProjectData(data) {
    return api.get(`/store/statistics/period/project`, {
      params: data,
    });
  },
  listPeriodProjectAccount(data) {
    return api.get(`/store/statistics/period/account-book`, {
      params: data,
    });
  },
  listHistoryProjectData(params) {
    return api.get(`/store/statistics/history/project`, {
      params: params,
    });
  },
  listHistoryStatistic(params) {
    return api.get(`/store/history/statistic`, {
      params: params,
    });
  },
  listClerkHistoryStatistic(data) {
    return api.get(`/store/staff/${data.pk}/history/statistic`, {
      params: data,
    });
  },
  //  合作店铺账单
  listCooperationPeriodProjectData(data) {
    return api.get(`/cooperation/statistics/period/project`, {
      params: data,
    });
  },
  listCooperationPeriodProjectDataTo(data) {
    return api.get(`/cooperation/statistics/period/project/to`, {
      params: data,
    });
  },
  listCooperationPeriodProjectBonus(data) {
    return api.get(`/cooperation/statistics/period/bonus`, {
      params: data,
    });
  },
  listCooperationPeriodProjectAccount(data) {
    return api.get(`/cooperation/statistics/period/account-book`, {
      params: data,
    });
  },

  listCooperationPeriodProjectAccountTo(data) {
    return api.get(`/cooperation/statistics/period/account-book/to`, {
      params: data,
    });
  },

  // 合作店铺历史
  listCooperationHistory(params) {
    return api.get(`/cooperation/statistics/history`, {
      params: params,
    });
  },
  listCooperationHistoryTo(params) {
    return api.get(`/cooperation/statistics/history/to`, {
      params: params,
    });
  },
  // listCooperationHistoryProjectData(params) {
  //   return api.get(`/cooperation/statistics/history/project`, {
  //     params: params
  //   });
  // },
  // 查询店铺内成员电话号码
  queryStaffCellphone(cellphone) {
    return api.get(`/store/user/${cellphone}`);
  },

  // 设置店铺角色
  setStaffRole(val) {
    return api.patch(`/store/staff/${val.pk}/role`, val);
  },
  // 获取店铺出票员
  getStaffPrint() {
    return api.get(`/store/staff-print`);
  },
  // 店主选择店员-出票员
  setProjectTransfer(val) {
    return api.patch(`/project/${val.pk}/transfer`, val);
  },

  // 查询店铺成员
  queryStaff() {
    return api.get(`/store/staff`);
  },
  // 绑定成员
  bindStaff(pk) {
    return api.patch(`/store/staff/bind/${pk}`);
  },
  // 解绑成员
  unbindStaff(pk) {
    return api.patch(`/store/staff/unbind/${pk}`);
  },
  // 获取合作商铺
  getCooperationTo(data) {
    return api.get(`/cooperation/to`, data);
  },
  // 获取合作商铺
  getCooperationFrom(data) {
    return api.get(`/cooperation/from`, data);
  },
  // 接单方获取所有合作关系
  getToCooperation(params) {
    return api.get(`/cooperation/to`, {
      params: params,
    });
  },
  getToCooperationPk(pk) {
    return api.get(`/cooperation/to/${pk}`);
  },
  // 接单方修改
  editCooperationTo(data) {
    return api.patch(`/cooperation/to`, data);
  },
  // 甩单方获取所有合作关系
  getFromCooperation(params) {
    return api.get(`/cooperation/from`, {
      params: params,
    });
  },
  getFromCooperationPk(pk) {
    return api.get(`/cooperation/from/${pk}`);
  },
  // 接单店铺给合作店铺加款
  addCooperationBalance(data) {
    return api.post(`/cooperation/book/add-balance`, data);
  },
  // 接单店铺给合作店铺扣款
  subCooperationBalance(data) {
    return api.post(`/cooperation/book/sub-balance`, data);
  },
  // 合作店铺账本
  getCooperationBook(params) {
    return api.get(`/cooperation/book`, {
      params: params,
    });
  },
  // 设置营业时间
  setReceiveHourSet(data) {
    return api.patch(`/store/receive-hours-setting`, data);
  },
  // 计点提醒计算
  remindPoint(data) {
    return api.patch(`/store/remind/point`, data);
  },
  // 合作余额
  remindCooperationBalance(data) {
    return api.patch(`/store/remind/cooperation-balance`, data);
  },
  getShareUrl() {
    return api.get(`/store/share-url`);
  },
  // 收单店铺排名榜
  getCooperationRank() {
    return api.get(`/store/cooperation-rank`);
  },
  // 收单店铺介绍
  getCooperationStoreIntroduce(id) {
    return api.get(`/store/${id}/introduce`);
  },
  // 获取管理员列表
  getStoreAdmin() {
    return api.get(`/store/admin`);
  },
};

export default store;
