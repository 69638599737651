<template>
  <div id="project-list" class="tab_list">
    <!-- <p-nav-bar ref="pNavBar"></p-nav-bar> -->
    <!-- <d-recharge ref="recharge"></d-recharge> -->
    <div class="filter van-hairline--bottom mt10">
      <van-dropdown-menu :close-on-click-outside="false">
        <van-dropdown-item
          v-model="order_by"
          :options="optionsOrderBy"
          @change="handleOrderChange"
        />
        <van-dropdown-item title="查询" ref="item">
          <van-field
            size="mini"
            v-model="project_id"
            label="方案号"
            placeholder="方案号"
          />
          <van-field
            size="mini"
            v-model="cellphone"
            label="手机号"
            placeholder="手机号"
          />
          <van-field
            v-model="lottery.text"
            label="彩种类型"
            readonly
            right-icon="arrow-down"
            @click-right-icon="lotteryShow = true"
            placeholder="彩种"
          />
          <div class="project-type">
            <span>方案类型</span>
            <div class="ml20">
              <van-radio-group v-model="cooperation" direction="horizontal">
                <van-radio icon-size="16px" name="0">普通</van-radio>
                <van-radio icon-size="16px" name="1">合单</van-radio>
              </van-radio-group>
            </div>
          </div>

          <div style="padding: 5px">
            <van-row :gutter="30">
              <van-col :span="12">
                <van-button size="small" block round @click="handleClearSearch">
                  取消
                </van-button>
              </van-col>
              <van-col :span="12">
                <van-button
                  size="small"
                  block
                  type="info"
                  round
                  @click="handleSearchChange"
                >
                  确认
                </van-button>
              </van-col>
            </van-row>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
    <div class="content">
      <van-pull-refresh
        v-if="data.length"
        v-model="refreshing"
        success-text="刷新成功"
        @refresh="handleRefreshList"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          @load="handleLoadList"
          :offset="100"
        >
          <div class="card" v-for="(item, key) in data" :key="key">
            <div class="card_div1 dis-flex dis-flex-center">
              <div class="card_div1_l flex1">
                <span class="number text_1"
                  >单号:{{ item.project_id.slice(-4) }}</span
                >
                <span
                  class="copy_text ml10 mr10"
                  @click="handleCopyContent(item.project_id)"
                  >复制</span
                >

                <div class="gen ml5" v-if="item.follow_type === 1">跟</div>
                <div class="fa ml5" v-if="item.follow_type === 2">发</div>
                <div class="he ml5" v-if="item.is_groupon === 1">合</div>
                <div
                  class="zhuan ml5"
                  v-if="
                    item.to_store_id !== item.store_id &&
                    item.to_store_id !== ''
                  "
                >
                  转
                </div>
                <div class="chuan_1 ml5" v-if="item.is_need_ticket === 1">
                  传
                </div>
              </div>
              <div class="card_div1_r text_1">
                <!-- 非合作订单 或者 合作订单甩单方 显示昵称 -->
                <div style="color: #2e5bfd" @click="handleToUserDetail(item)">
                  昵称：{{ item.user.remark || item.user.nickname }}
                </div>
              </div>
            </div>
            <div class="card_div2 dis-flex dis-flex-center mt5">
              <div class="card_div2_l flex1 dis-flex dis-flex-center">
                <span class="type">{{ item.type | formatProjectType }}</span>
                <span v-if="item.cancel_type !== 0"
                  >（{{ item.cancel_type | formatCancelType }}）</span
                >

                <span
                  class="periods ft16 ml7"
                  v-if="
                    item.type !== 'bd' &&
                    item.type !== 'jczq' &&
                    item.type !== 'jclq' &&
                    item.type !== 'sfgg' &&
                    item.type !== 'jcgj' &&
                    item.type !== 'jcgyj'
                  "
                  >{{ item.bet_match }}期</span
                >
                <!-- <div class="ml7 chuan ell" v-if="item.pass_type">
                    <span v-for="(o, i) in item.pass_type.split(',')" :key="i">
                      {{ o | formatProjectPassType }}
                    </span>
                  </div> -->
                <!-- <div class="multiple ml7">{{ item.bet_multi }}倍</div> -->
              </div>
              <div class="card_div2_r">
                <span class="ft14">{{ item.handled_at | formatDateTime }}</span>
              </div>
            </div>
            <div class="card_div4 dis-flex mb5 dis-flex-center">
              <div class="card_div4_r price_text">
                ¥<span class="price">{{ item.bet_money }}</span>
              </div>
            </div>
            <div class="card_div5 dis-flex">
              <div class="card_div5_l flex1"></div>

              <div class="card_div5_r flex1">
                <div class="view_btn fr" @click="handleGoDetail(item)">
                  查看
                  <span v-if="item.type !== 'jcgj' && item.type !== 'jcgyj'"
                    >{{ item.ticket_count }}张票</span
                  >
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <van-empty v-else description="暂无记录" />
    </div>

    <!-- 底部选择彩种 -->
    <van-popup
      v-model="lotteryShow"
      position="bottom"
      :style="{ height: '30%', bottom: '50px' }"
    >
      <van-picker
        title="标题"
        show-toolbar
        :columns="lotteryColumns"
        @confirm="handleConfirm"
        @cancel="lotteryShow = false"
      />
    </van-popup>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="staffPrintList"
        @confirm="handleSendConfirm"
        value-key="nickname"
        @cancel="showPicker = false"
      />
    </van-popup>
    <c-tabbar></c-tabbar>
  </div>
</template>

<script>
import CTabbar from "@/components/CTabbar";
import dayjs from "dayjs";

import project from "@/api/project";
// import account from "@/api/account";
import store from "@/api/store";

import { mapState } from "vuex";

export default {
  name: "project-list",
  data() {
    return {
      lotteryColumns: [
        {
          text: "竞彩足球",
          value: "jczq",
        },
        {
          text: "竞彩篮球",
          value: "jclq",
        },
        {
          text: "北单",
          value: "bd",
        },
        {
          text: "任选9场",
          value: "sfc9",
        },
        {
          text: "足球14场",
          value: "sfc14",
        },
        {
          text: "大乐透",
          value: "dlt",
        },
        {
          text: "双色球",
          value: "ssq",
        },
        {
          text: "排列三",
          value: "pls",
        },
        {
          text: "排列五",
          value: "plw",
        },
        {
          text: "七星彩",
          value: "qxc",
        },
        {
          text: "福彩3D",
          value: "sd",
        },
        {
          text: "七乐彩",
          value: "qlc",
        },
        {
          text: "快乐8",
          value: "klb",
        },
      ],
      showPicker: false,
      lotteryShow: false,
      questionShow: false,
      data: [],
      countDownData: [],
      refreshing: false, // 下拉刷新
      finished: false, // 上拉加载完毕
      loading: false, // 上拉加载
      page: 1,
      order_by: "-created_at",
      tag: 0,
      state: "",
      project_id: "",
      cellphone: "",
      lottery: {},
      cooperation: "",
      optionsOrderBy: [
        {
          text: "下单正序",
          value: "created_at",
        },
        {
          text: "下单倒序",
          value: "-created_at",
        },
        {
          text: "截止正序",
          value: "expire_time",
        },
      ],
      optionsState: [
        {
          text: "全部",
          value: "",
        },
        {
          text: "待出票",
          value: "0",
        },
        {
          text: "失败",
          value: "-1",
        },
      ],
      tagState: [
        {
          text: "全部",
          value: "",
        },
        {
          text: "本店/收单",
          value: 0,
        },
        {
          text: "甩单",
          value: 1,
        },
        {
          text: "收单",
          value: 2,
        },
        {
          text: "本店",
          value: 3,
        },
      ],
      todo: {},
      staffPrintList: [],
      sendObj: {},
    };
  },
  computed: {
    ...mapState({
      store: (state) => state.store,
      lottery_setting: (state) => state.lottery_setting,
      business_hours_setting: (state) => state.business_hours_setting,
      account: (state) => state.account,
    }),
  },
  activated() {
    this.handleRefreshList();
    // this.handleGetStaffList();
    // this.$store.dispatch("getTodoInfo").then((res) => {
    //   this.todo = res;
    //   if (res.unread_question_count > 0) {
    //     this.questionShow = true;
    //   }
    // });
  },
  components: {
    CTabbar,
    //   PNavBar,
    //   DRecharge
  },
  methods: {
    handleDelay(pk) {
      project
        .setProjectDelay(pk)
        .then(() => {
          this.data = [];
          this.handleList();
          this.$toast("稍后抢成功");
        })
        .catch((res) => {
          this.$toast(res.message);
        });
    },
    handleToUserDetail(item) {
      this.$router.push({
        name: "account-book-customer-detail",
        query: {
          user_id: item.user_id,
        },
      });
    },
    handleSendConfirm(val) {
      if (!val.user_id) {
        this.$toast("暂无可派单人员");
        return false;
      }
      store
        .setProjectTransfer({
          pk: this.sendObj.pk,
          staff_user_id: val.user_id,
        })
        .then(() => {
          this.$toast("派单成功");
          this.showPicker = false;
          this.handleRefreshList();
        })
        .catch(() => {
          this.$toast("派单失败");
          this.showPicker = false;
        });
    },
    handleGetStaffList() {
      store.getStaffPrint().then((res) => {
        this.staffPrintList = res.results;
      });
    },
    handleSend(val) {
      this.showPicker = true;
      this.sendObj = val;
    },
    handleCopyContent(val) {
      this.$toast("单号已复制");
      this.copyEvent(val);
    },
    handleCalCooperation(item) {
      return item.to_store_id !== item.store_id && item.to_store_id !== "";
    },
    handleFailed(data) {
      this.$dialog
        .confirm({
          message: "是否确认撤单？",
        })
        .then(() => {
          let pk = data.pk;
          if (
            data.type === "jczq" ||
            data.type === "jclq" ||
            data.type === "jcgj" ||
            data.type === "jcgyj"
          ) {
            project
              .printSportsFailed(pk)
              .then(() => {
                this.$toast({
                  message: "撤单成功！",
                });
                this.handleRefreshList();
                this.$refs.pNavBar.handleInit();
              })
              .catch((err) => {
                this.$toast({
                  message: err.message,
                });
              });
          } else if (data.type == "bd") {
            project
              .printBdFailed(pk)
              .then(() => {
                this.$toast({
                  message: "撤单成功！",
                });
                this.handleRefreshList();
                this.$refs.pNavBar.handleInit();
              })
              .catch((err) => {
                this.$toast({
                  message: err.message,
                });
              });
          } else {
            project
              .printNumberFailed(pk)
              .then(() => {
                this.$toast({
                  message: "撤单成功！",
                });
                this.handleRefreshList();
                this.$refs.pNavBar.handleInit();
              })
              .catch((err) => {
                this.$toast({
                  message: err.message,
                });
              });
          }
        })
        .catch(() => {});
    },
    handleOrderChange() {
      this.handleRefreshList();
    },
    handleTagChange() {
      this.handleRefreshList();
    },
    handleStateChange(val) {
      location.hash = `#${val}`;
      if (val === "0") {
        this.order_by = "created_at";
      }
      if (val === "") {
        this.order_by = "-created_at";
      }
      this.handleRefreshList();
    },
    handleSearchChange() {
      location.hash = "";
      this.tag = "";
      this.state = "";
      this.handleRefreshList();
      this.$refs.item.toggle();
    },
    handleClearSearch() {
      this.project_id = "";
      this.cellphone = "";
      this.handleRefreshList();
      this.$refs.item.toggle();
    },
    handleRefreshList() {
      this.page = 1;
      this.refreshing = true;
      this.handleList();
    },
    handleLoadList() {
      this.page++;
      this.loading = true;
      this.handleList();
    },
    handleList() {
      let params = {
        page: this.page,
        cellphone: this.cellphone,
        cooperation: this.cooperation,
      };
      if (this.state === "0" || this.state === "-1") {
        params.state = this.state;
      }
      if (this.order_by) {
        params.order_by = this.order_by;
      }
      if (this.project_id) {
        params.project_id = this.project_id;
      }
      if (this.lottery.value) {
        params.lottery_type = this.lottery.value;
      }
      if (this.tag !== "") {
        params.tag = this.tag;
      }
      console.log(params);
      project
        .list(params)
        .then((res) => {
          if (this.data.length > 0) {
            if (this.loading) {
              this.data = this.data.concat(res.results);
              this.$nextTick(() => {
                this.loading = false;
              });
            }
            if (this.refreshing) {
              this.refreshing = false;
              this.data = res.results;
              if (this.finished) {
                this.finished = false;
              }
            }
          } else {
            this.data = res.results;
          }

          if (res.results.length < 10) {
            this.finished = true;
          }
          // this.$store.commit("setNotifyShow", false);
        })
        .finally(() => {
          this.refreshing = false;
          this.loading = false;
        });
    },
    handleGoDetail(item) {
      // 判断是否是合作单
      let orderType = "";
      if (item.is_view !== 1) {
        this.$toast({
          message: "未查看方案暂无权限",
        });
        return false;
      }

      if (item.type === "jczq" || item.type === "jclq") {
        this.$router.push({
          name: "project-sports-detail",
          query: {
            pk: item.pk,
            type: item.type,
            project_id: item.project_id,
            orderType: orderType,
          },
        });
      } else if (item.type === "bd") {
        this.$router.push({
          name: "project-bd-detail",
          query: {
            pk: item.pk,
            type: item.type,
            project_id: item.project_id,
            orderType: orderType,
          },
        });
      } else if (item.type === "jcgj" || item.type === "jcgyj") {
        this.$router.push({
          name: "project-gj-detail",
          query: {
            pk: item.pk,
            type: item.type,
            project_id: item.project_id,
            orderType: orderType,
          },
        });
      } else {
        this.$router.push({
          name: "project-number-detail",
          query: {
            pk: item.pk,
            type: item.type,
            project_id: item.project_id,
            orderType: orderType,
          },
        });
      }
    },
    handleCountDown() {
      //  取开店时间分钟 关店时间分钟 若截止分钟 在开店分钟到关店分支内 取截止时间 若不在 取截止时间前面最近的关店时间
      this.countDownData = [];
      let lottery_setting = this.lottery_setting;
      let business_hours_setting = this.business_hours_setting;
      let weekday = dayjs().day();
      let now = business_hours_setting[weekday];
      this.data.forEach((o) => {
        let t = "";
        // 判断福彩3D 安徽/甘肃地区 截止时间加上半小时

        let areaArray = ["62", "34", "36", "14", "50", "44", "64", "32", "22"];
        let areaArray1 = ["44"];
        let area = "00";
        if (areaArray.includes(area.slice(0, 2)) && o.type === "sd") {
          t = dayjs(o.expire_time)
            .add(30, "minute")
            .diff(dayjs(), "millisecond");
        } else if (areaArray1.includes(area.slice(0, 2)) && o.type === "klb") {
          t = dayjs(o.expire_time).subtract(10, "minute");
        } else {
          t = dayjs(o.expire_time).diff(dayjs(), "millisecond");
        }

        if (t <= 0) {
          let s = dayjs(o.expire_time);
          this.countDownData.push([s.format("MM-DD"), s.format("HH:mm"), 0]);
        } else {
          let temp =
            o.type === "bd"
              ? lottery_setting[o.type]
              : {
                  start_time: now.start,
                  end_time: now.end,
                };

          let temp_start =
            Number(temp.start_time.split(":")[0]) * 60 +
            Number(temp.start_time.split(":")[1]);
          let temp_end =
            Number(temp.end_time.split(":")[0]) * 60 +
            Number(temp.end_time.split(":")[1]);

          let match_time_minute =
            dayjs(o.expire_time).hour() * 60 + dayjs(o.expire_time).minute();

          if (temp_end > temp_start) {
            if (
              match_time_minute < temp_end &&
              match_time_minute >= temp_start
            ) {
              // 在营业时间 取截止时间
              let s = dayjs(o.expire_time);
              this.countDownData.push([
                s.format("MM-DD"),
                s.format("HH:mm"),
                t,
              ]);
            } else {
              if (match_time_minute >= temp_end) {
                // 不在营业时间 如何截止分钟大于 关店分钟 取当天截止时间
                let c = dayjs(o.expire_time).format("YYYY-MM-DD");
                let c1 = dayjs(c + " " + temp.end_time);
                let c2 =
                  c1.diff(dayjs(), "millisecond") < 0
                    ? 0
                    : c1.diff(dayjs(), "millisecond");

                this.countDownData.push([
                  c1.format("MM-DD"),
                  c1.format("HH:mm"),
                  c2,
                ]);
              } else {
                // 不在营业时间 如何截止分钟小于 开店分钟 取前一天截止时间

                let last_day = dayjs(o.expire_time).subtract(1, "day").day();

                let c = dayjs(o.expire_time)
                  .subtract(1, "day")
                  .format("YYYY-MM-DD");

                let c1 = dayjs(c + " " + business_hours_setting[last_day].end);
                let c2 =
                  c1.diff(dayjs(), "millisecond") < 0
                    ? 0
                    : c1.diff(dayjs(), "millisecond");
                this.countDownData.push([
                  c1.format("MM-DD"),
                  c1.format("HH:mm"),
                  c2,
                ]);
              }
            }
          }

          if (temp_end < temp_start) {
            if (
              match_time_minute < temp_end ||
              match_time_minute >= temp_start
            ) {
              // 在营业时间 取截止时间
              let s = dayjs(o.expire_time);
              this.countDownData.push([
                s.format("MM-DD"),
                s.format("HH:mm"),
                t,
              ]);
            } else {
              // 不在营业时间 取当天截止时间
              let c = dayjs(o.expire_time).format("YYYY-MM-DD");
              let c1 = dayjs(c + " " + temp.end_time);
              let c2 =
                c1.diff(dayjs(), "millisecond") < 0
                  ? 0
                  : c1.diff(dayjs(), "millisecond");
              this.countDownData.push([
                c1.format("MM-DD"),
                c1.format("HH:mm"),
                c2,
              ]);
            }
          }
        }
      });
    },
    handleConfirm(value) {
      this.lottery = value;
      this.lotteryShow = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
#project-list {
  height: 100%;
  background: #f0f0f0;
  background: url('../../assets/images/index/bg.png') 100% top no-repeat
  background-size: 100% auto;
  .filter {
    position: fixed;
    left: 0;
    right: 0;
    top: 46px;
    z-index: 888;
    .project-type {
      display flex
      align-items center
      height 44px;
      padding: 10px 16px;
      font-size 14px
      color #323233
      span{
        color #646566
      }
    }
  }

  .content {
    position: fixed;
    left: 0;
    right: 0;
    top: 100px;
    bottom: 50px;
    padding: 10px;
    overflow-y: scroll;



    .card {
      color: #72748A;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      .text_1{
        color: #72748A;
        font-size: 12px;
      }
      .text_2{
        color: #72748A;
        font-size: 16px;
      }
      .copy_text{
        color: #2E5BFD;
        font-size: 12px;
      }

      .type{
        color: #EE4631;
        font-size: 16px;
        font-weight: 500;
      }

      .chuan{
        background:#ffe295;
        color: #886c23
        padding: 2px 5px;
        border-radius: 3px;
        max-width: 70px;
      }

      .multiple{
        background: #F2E3D6;
        color: #784734;
        padding: 2px 5px;
        border-radius: 3px;
      }

      .status{
        display inline-block
        font-size 15px
        color #fff
        padding 5px 7px
        border-radius 4px
        width: 100px;
        text-align:center;
      }

      .gen{
        font-weight: 500;
        padding: 3px;
        background: #379CFB;
        border-radius: 3px;
        display: inline-block;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 100%;
        text-align: center;
      }

      .fa{
        font-weight: 500;
        padding: 3px;
        background: #379CFB;
        border-radius: 3px;
        display: inline-block;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 100%;
        text-align: center;
      }

      .he{
        font-weight: 500;
        padding: 3px;
        background: #379CFB;
        border-radius: 3px;
        display: inline-block;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 100%;
        text-align: center;
      }

      .zhuan{
        font-weight: 500;
        padding: 3px;
        background: #379CFB;
        border-radius: 3px;
        display: inline-block;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 100%;
        text-align: center;
      }

      .chuan_1{
        font-weight: 500;
        padding: 3px;
        background: #F55133;
        border-radius: 3px;
        display: inline-block;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 100%;
        text-align: center;
      }

      .price_text{
        color: #F84848;
      }

      .price{
        color: #F84848;
        font-size: 24px;
        font-weight: 800;
      }


      .color0 {
        background #EDF3FF
        color #2E6BEF
      }
      .color1 {
        background #FFE5E8
        color #EE4631
      }
      .color2,.color-1 {
        background #EBEEF7
        color #72748A
      }
      .color3 {
        background: linear-gradient(146deg, #F84848 , rgba(255, 116, 68, 0.64));
        color #fff
      }

      .color4 {
        background linear-gradient(135deg, #BA9379 , rgba(230, 185, 141, 0.48));
        color #fff
      }

      .refuse_btn{
        height: 33px;
        background: #F6F7FB;
        border-radius: 17px;
        color: #72748A;
        font-size: 16px;
        width: 80%;
        line-height:33px;
        text-align: center;
        font-weight: 500;
      }

      .view_btn{
        height: 33px;
        background: linear-gradient(270deg, #2E5AFD 0%, #5D89FE 100%);;
        border-radius: 17px;
        color: #fff;
        font-size: 16px;
        width: 80%;
        line-height:33px;
        text-align: center;
        font-weight: 500;
      }
    }
  }
}
</style>
