<template>
  <div id="customer">
    <div class="filter">
      <van-search
        clearable
        v-model="keyword"
        show-action
        placeholder="昵称/手机号码"
        @cancel="handleCancel"
      >
        <template #action>
          <div @click="handleSearch">搜索</div>
        </template>
      </van-search>
    </div>
    <div class="count">
      <span class="count-customer">共{{ count }}位客户</span>
      <span>
        <van-icon
          @click="handleSort('ascending')"
          class="pt3 ft18"
          name="ascending"
          v-if="order_by === 'balance,pk'"
        />
        <van-icon
          v-else
          @click="handleSort('descending')"
          class="pt3 ft18"
          name="descending"
        />
      </span>
    </div>
    <div class="body">
      <van-pull-refresh
        v-if="data.length"
        v-model="refreshing"
        success-text="刷新成功"
        @refresh="handleRefreshList"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          @load="handleLoadList"
          :offset="100"
        >
          <van-cell
            v-for="(item, key) in data"
            :key="key"
            :title="item.user.remark || item.user.nickname"
            :label="item.balance | formatMoneyValue"
            :value="item.last_active_at | formatCustomerActive"
            center
            @click="handleGoDetail(item.user.user_id)"
          />
        </van-list>
      </van-pull-refresh>
      <van-empty v-else description="暂无记录"></van-empty>
    </div>
    <c-tabbar></c-tabbar>
  </div>
</template>

<script>
import account from "@/api/account";

import CTabbar from "@/components/CTabbar.vue";

export default {
  name: "customer",
  data() {
    return {
      keyword: "",
      data: [],
      refreshing: false, // 下拉刷新
      finished: false, // 上拉加载完毕
      loading: false, // 上拉加载
      page: 1,
      count: 0,
      order_by: "-balance,pk",
    };
  },
  components: {
    CTabbar,
  },
  created() {
    this.handleList();
  },
  methods: {
    handleSearch() {
      this.handleRefreshList();
    },
    handleCancel() {
      this.keyword = "";
      this.handleRefreshList();
    },
    handleRefreshList() {
      this.page = 1;
      this.refreshing = true;
      this.handleList();
    },
    handleLoadList() {
      this.page++;
      this.loading = true;
      this.handleList();
    },
    handleSort(val) {
      this.page = 1;
      this.order_by = val === "ascending" ? "-balance,pk" : "balance,pk";
      this.data = [];
      this.handleList();
    },
    handleList() {
      let params = {
        page: this.page,
        order_by: this.order_by,
      };
      if (this.keyword) {
        params.keyword = this.keyword;
      }
      account
        .listCustomer(params)
        .then((res) => {
          this.count = res.count;
          if (this.data.length > 0) {
            if (this.loading) {
              this.data = this.data.concat(res.results);
              this.$nextTick(() => {
                this.loading = false;
              });
            }
            if (this.refreshing) {
              this.refreshing = false;
              this.data = res.results;
              if (this.finished) {
                this.finished = false;
              }
            }
          } else {
            this.data = res.results;
          }

          if (res.results.length < 10) {
            this.finished = true;
          }
        })
        .finally(() => {
          this.refreshing = false;
          this.loading = false;
        });
    },
    handleGoDetail(pk) {
      this.$router.push({
        name: "customer-detail",
        query: { pk: pk },
      });
    },
  },
};
</script>

<style lang="stylus">
#customer {
  .filter {
    position: fixed;
    left: 0;
    right: 0;
    background: #ffffff;
  }

  .count {
    position: fixed;
    display: flex;
    left: 0;
    right: 0;
    top: 54px;
    padding: 10px;
    justify-content: space-between

    .count-customer {
      font-size: 14px;
      color: #38A6F3;
    }
  }

  .body {
    position: fixed;
    left: 0;
    right: 0;
    top: 96px;
    bottom: 50px;
    overflow-y: scroll;
  }
}
</style>
